import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import ContactBotMap from "../components/contact-bot-map"
import PageHeading from "../components/page-heading"

const BuergerPage = () => {
  const data = useStaticQuery(graphql`
    query {
      examplePic: file(relativePath: { eq: "worldmap-pic-bench.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenAttachements: file(relativePath: { eq: "screen-kreis.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slideBlue: file(relativePath: { eq: "slide-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gifOverlay: file(relativePath: { eq: "GIF-overlay.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Für Bürger"
        description="Nehmen Sie Kontakt mit dem BürgerBot Ihrer Stadt auf und schicken Sie Ihre Anliegen per Smartphone."
      />
      <PageHeading background={true}>
        <p>
          Die Bedienung des BürgerBots lässt sich in drei einfachen Schritten
          erklären.
          <br />
          Folgen Sie der Beschreibung und melden Sie Ihr Anliegen an die Stadt.
        </p>
      </PageHeading>

      <section
        className="section padding-top-70 padding-bottom-100"
        data-aos="fade-up"
      >
        <Container>
          <Row>
            <Col
              xs={12}
              className="d-block d-md-none align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.examplePic.childImageSharp.fluid} />
            </Col>
            <Col xs={12} md={6} className="align-self-center mobile-top-fix">
              <div className="left-heading">
                <h2 className="section-title">Schritt 1 — Problem sehen</h2>
              </div>
              <div className="left-text">
                <p>
                  Der BürgerBot ist Ihre Kontaktmöglichkeit zur Stadt und nimmt
                  gerne Ihre Anliegen entgegen. Sehen Sie beispielsweise einen
                  überfüllten Mülleimer oder eine beschädigte Parkbank, können
                  Sie dies dem BürgerBot mitteilen. Schreiben Sie dem Bot dazu
                  wie einer normalen Person.
                </p>
              </div>
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col
              md={6}
              lg={5}
              className="d-none d-md-block align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.examplePic.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="hr"></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-up">
        <Container>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={5}
              className="align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.screenAttachements.childImageSharp.fluid} />
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col xs={12} md={6} className="align-self-center mobile-top-fix">
              <div className="left-heading">
                <h2 className="section-title">
                  Schritt 2 — Melden mit dem Smartphone
                </h2>
              </div>
              <div className="left-text">
                <p>
                  Zur Kontaktaufnahme benötigen Sie Ihr Smartphone und eine der
                  unterstützen Messenger-Apps (z. B. WhatsApp, Facebook
                  Messenger, Telegram). Finden Sie die Kontaktdaten zum
                  BürgerBot Ihrer Stadt für Ihren Messenger, beschreiben Sie Ihr
                  Anliegen und senden Sie falls gewünscht passende Fotos und
                  Ihren Standort mit.
                </p>
                <div data-aos="fade-up" data-aos-delay="100">
                  <a
                    href="#contact-bot-map"
                    className="custom-btn btn mt-3 mr-3"
                  >
                    Kontaktdaten finden
                  </a>
                  <Link to="/faq" className="custom-btn btn mt-3 mr-3">
                    FAQ
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="hr"></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section padding-bottom-100" data-aos="fade-up">
        <Container>
          <Row>
            <Col
              xs={12}
              className="d-block d-md-none align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <div className="image-overlay">
                <Img fluid={data.gifOverlay.childImageSharp.fluid} />
                <img
                  src="/images/GIF-texting.gif"
                  className="rounded img-fluid d-block mx-auto"
                  alt="App"
                />
              </div>
            </Col>
            <Col xs={12} md={6} className="align-self-center mobile-top-fix">
              <div className="left-heading">
                <h2 className="section-title">
                  Schritt 3 — Absenden und fertig
                </h2>
              </div>
              <div className="left-text">
                <p>
                  Sobald Sie Ihr Anliegen beschrieben haben, tippen Sie
                  "Absenden" und der BürgerBot wird Ihre Anfrage an seine
                  menschlichen Kolleginnen und Kollegen weiterleiten. Falls bei
                  der Bearbeitung noch weitere Informationen benötigt werden,
                  bekommen Sie Rückfragen direkt auf Ihr Smartphone.
                </p>
              </div>
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col
              md={6}
              lg={5}
              className="d-none d-md-block align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <div className="image-overlay">
                <Img fluid={data.gifOverlay.childImageSharp.fluid} />
                <img
                  src="/images/GIF-texting.gif"
                  className="rounded img-fluid d-block mx-auto"
                  alt="App"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ContactBotMap />
    </Layout>
  )
}
export default BuergerPage
